.matching-container {
    display: flex;
    justify-content: space-between;
  }
  
  .matching-items, .matching-options {
    width: 45%;
  }
  
  .matching-item {
    border: 1px solid #665cf6;
    padding: 10px;
    margin-bottom: 10px;
    min-height: 50px;
  }
  
  .matching-option {
    background: rgba( 58, 1, 114, 0.7 );
    padding: 5px;
    margin: 5px;
    cursor: move;
    /* will-change: transform; */
  }
  
  .dragging-over {
    background-color: yellow;
  }
  
  .dragging {
    opacity: 0.5;
  }
  