body[data-theme='default'] .card {
  background-color: white;
  color: black;
  transition: background-color 0.3s ease-out; /* Smooth transition */
}
body[data-theme='default'] .reason {
  background: rgba( 255, 255, 255, 0.8 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2.5px );
  -webkit-backdrop-filter: blur( 2.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
body[data-theme='light'] .reason {
  background: rgba( 108, 21, 202, 0.8 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2.5px );
  -webkit-backdrop-filter: blur( 2.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );

}


body[data-theme='dark'] .reason{
  background: rgba( 58, 1, 114, 0.7 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2.5px );
  -webkit-backdrop-filter: blur( 2.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );

}

/* Highlighted card for all themes */
body[data-theme='dark'] .card.highlighted,
body[data-theme='default'] .card.highlighted,
body[data-theme='light'] .card.highlighted {
  /* background-color: rgb(255, 132, 0) !important; */
  /* color: #fff; Ensure text is readable */
  animation: heartBeat 6s ease-in-out, neonBorder 2s infinite alternate;
}

/* Heartbeat animation */
@keyframes heartBeat {
  0% { transform: scale(1); }
  25% { transform: scale(1.1); }
  50% { transform: scale(1); }
  75% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes neonBorder {
  0% {
    box-shadow: 0 0 1px 0 rgba(255, 255, 255, 1), 0 0 4px 0 rgba(255, 255, 255, 0.8), 0 0 6px 0 rgba(255, 255, 255, 0.6); /* White neon glow */
    border: 2px solid rgba(255, 255, 255, 0.8); /* White border */
  }
  25% {
    box-shadow: 0 0 1px 0 rgba(0, 0, 255, 1), 0 0 4px 0 rgba(0, 0, 255, 0.8), 0 0 6px 0 rgba(0, 0, 255, 0.6); /* Blue neon glow */
    border: 2px solid rgba(0, 0, 255, 0.8); /* Blue border */
  }
  50% {
    box-shadow: 0 0 1px 0 rgba(255, 255, 0, 1), 0 0 4px 0 rgba(255, 255, 0, 0.8), 0 0 6px 0 rgba(255, 255, 0, 0.6); /* Yellow neon glow */
    border: 2px solid rgba(255, 255, 0, 0.8); /* Yellow border */
  }
  75% {
    box-shadow: 0 0 1px 0 rgba(64, 224, 208, 1), 0 0 4px 0 rgba(64, 224, 208, 0.8), 0 0 6px 0 rgba(64, 224, 208, 0.6); /* Turquoise neon glow */
    border: 2px solid rgba(64, 224, 208, 0.8); /* Turquoise border */
  }
  100% {
    box-shadow: 0 0 1px 0 rgba(255, 0, 255, 1), 0 0 4px 0 rgba(255, 0, 255, 0.8), 0 0 6px 0 rgba(255, 0, 255, 0.6); /* Purple neon glow */
    border: 2px solid rgba(255, 0, 255, 0.8); /* Purple border */
  }
}
/* .highlighted {
  background-color: rgb(255, 132, 0); 
  animation: heartBeat 1.5s ease-in-out;
}



@keyframes heartBeat {
  0% { transform: scale(1); }
  25% { transform: scale(1.1); }
  50% { transform: scale(1); }
  75% { transform: scale(1.1); }
  100% { transform: scale(1); }
} */

.matching-question {
  margin-top: 15px;
}

.matching-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.matching-items, .matching-options {
  width: 48%;
}

.matched-option {
  background: rgba(58, 1, 114, 0.7);
}

.matching-item, .matching-option {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: var(--background-color);
}

.correct-matches {
  margin-top: 20px;
  max-width: 100%;
}

.correct-match-item {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.match-content {
  /* display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: var(--background-color); */
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  width: 100%;
}

.item-content {
  flex-shrink: 0;
  font-weight: bold;
  margin-right: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 40%;
  /* background-color: var(--background-color); */
  /* padding: 0 5px;
  position: relative;
  z-index: 2;  */
}

.match-arrow {
  flex-shrink: 0;
  margin: 0 10px;
  font-size: 1.2em;
  /* background-color: var(--background-color); */
  /* padding: 0 0px;
  padding-right: 10px;
  position: relative;
  z-index: 2;  */
}

.matched-options {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 55%;
}

.match-line {
  height: 2px;
  background-color: red;
  width: 100%;
  margin-top: 5px;
}

.correct-option {
  color: green;
  margin: 2px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  /* background-color: var(--background-color); */
  /* padding: 0 5px;
  position: relative;
  text-align: right;
  z-index: 2;  */
}

/* .highlighted {
  background-color: rgb(255, 132, 0); 
  transition: background-color 0.3s ease-out; 

} */
.card {
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    background-color: var(--background-color);
    position: relative;
    color: var(--text-color);
    width: 100%;
    max-width: 600px;
    min-height: 200px; /* Ensures space for loader */
    transition: background-color 0.3s ease-out; /* Smooth transition */
  }

  /* body[data-theme='default'].highlighted {
    background-color: rgb(255, 132, 0);
    transition: background-color 0.3s ease-out; 
  }

 */
  
  .reason{
    font-weight: bold;
  }
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Ensure full width for centering */
    
    padding: 16px; /* Optional: adds some space around the container */   
  }

  .card img {
    max-width: 100%; /* Ensure images fit within the container */
    height: auto; /* Maintain aspect ratio */
  }
  
  .canvas {
    
    max-width: 100%; /* Fit within the container */
    height: auto; /* Maintain aspect ratio */
  }
  
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #800080;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.5s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .correct {
    background-color: #d4edda;
    color: #155724;
    padding: 0px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .checkmark {
    color: #007bff;
    font-size: 18px;
    margin-left: 8px;
  }
  
  .reason {
    padding: 12px;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  img {
    max-width: 100%;
    margin-top: 8px;
    border-radius: 1px;
  }
  
  ol {
    list-style-type:lower-alpha;
    padding: 5px;

  }
  li.correct{
    margin-top: 5px;
  }
  
  mark {
    background-color: yellow;
    color: black;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333,0,0,0.7;
    backdrop-filter: blur(2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .hamburger-menu {
    background-color: transparent;
    color: white;
    font-size: 24px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .search-container {
    position: relative;
  }
  
  .search-icon {
    font-size: 24px;
    cursor: pointer;
    color: white;
  }
  
  .search-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 0;
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
  }
  
  .search-container:hover .search-input {
    width: 150px;
    opacity: 1;
    visibility: visible;
  }
  
  .chapter-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .nav-button {
    background-color: #07f90f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }

  .nav-button-Back {
    background-color: #430202;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .chapter-dropdown {
    margin: 0 10px;
  }
  
  .study-robot {
    font-size: 24px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    animation: float 2s infinite;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }

  }
  
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 20px;
    }
  
    .hamburger-menu {
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      font-size: 24px;
    }
    ol {
      list-style-type:lower-alpha;
      padding: 10px;
  
    }
    .search-input {
      width: 100%;
      margin-top: 10px;
    }
  
    .card {
      /* width: 90%; */
      margin: 0%;
    
    }
    ul li{
      list-style-type: none;
      margin: 0%;
    }
      .match-content {
    flex-direction: column;
  }

  .item-content,
  .matched-options {
    max-width: 100%;
  }

  .match-arrow {
    margin: 5px 0;
    color:rgba( 255, 255, 255, 0 )
  }
    
  }
  @media (max-width: 540px) and (orientation: portrait){
    .card ul{
      margin: 0%;
      padding:0%;
    }
    .card {
      margin: 16px;
    }
    
    .reason p{
      padding: 0%;
      margin: 0%;
      text-align: left;
    }
    .reason {
      padding: 0%;
      margin: 0%;
      text-align: left;
    }

    .card-container {

      width: 100%; /* Ensure full width for centering */
      max-width: 100%;
      padding: 0px;
    }
    .current-chapter{
    padding-left: 100px;
    }
    ul li{
      list-style-type: none;
      margin: 0%;
    }
    il.correct{
      padding: 0%;
      margin: 0%;
      text-align: left;
    }
    /* ul.correct{
      padding: 0%;
    margin: 0%;
    text-align: left;
  } */
  }
  