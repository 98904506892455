#core-concepts {
  padding: 2rem;
  border-radius: 6px;
  background-color: #140524;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  width: auto;
}

/* #core-concepts {
  padding: 2rem;
  border-radius: 6px;
  background-color: #140524;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
} */





#core-concepts li .active {
  opacity: 1;
}