.code-concept-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center the cards in the container */
  list-style: none;
  padding: 0;
}

.code-concept-card {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(100px);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 40px); /* Adjust this value to control the card width */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center text inside the card */
  word-wrap: break-word; /* Ensure text wraps within the card */
  box-sizing: border-box; /* Ensure padding is included in width/height */
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-link {
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.5rem;
  margin: 10px 0;
}

.card-description {
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
}

/* Media Query for Mobile Devices */
@media (max-width: 420px) {
   
}
