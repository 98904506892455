/* .sitemap {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .sitemap ul {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .sitemap li {
    margin-bottom: 10px;
  }
  
  .sitemap a {
    text-decoration: none;
    color: #333;
  }
  
  .sitemap a:hover {
    text-decoration: underline;
  } */


  .sitemap {
    margin-top: 20px;
  }
  
  .sitemap a {
    text-decoration: none;
    color: inherit;
  }
  
  .sitemap ul {
    list-style: none;
    padding-left: 0;
  }
  
  .sitemap li {
    margin: 10px 0;
  }
  
  .sitemap button {
    margin-top: 8px;
  }
  