.footer-glossy {
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    backdrop-filter: blur(100px); /* Frosted glass effect */
    border-radius: 15px; /* Rounded edges */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 2rem;
    margin: 2rem;

}

.footer-glossy h3 {
    color: #6a0dad; /* Purple color for titles */
    margin-bottom: 0.5rem; /* Space between title and list */
}

.footer-glossy ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.footer-glossy a {
    color: #9b59b6; /* Purple text for links */
    text-decoration: none;
}

.footer-glossy a:hover {
    color: #8e44ad; /* Darker purple on hover */
}

.email-input {
    border: 2px solid #dcdcdc; /* Light gray border */
    width: auto; /* Make the input longer */
    height: 48px; /* Increase height */
    padding: 0 1rem; /* Padding inside the input */
    border-radius: 9999px; /* Fully rounded */
}

.subscribe-button {
    background-color: #6a0dad; /* Purple button background */
    color: white;
    height: 48px; /* Match the input height */
    width: 140px;
    border: none;
    border-radius: 25px; /* Fully rounded */
    margin-left: 1%; /* Margin for spacing */
    cursor: pointer;
    background: linear-gradient(45deg, purple, #ea00ff, purple, #ea00ff);
}

.subscribe-button:hover {
    background-color: #8e44ad; /* Darker purple on hover */
}

.social-icons {
    display: flex;
    justify-content: center; /* Center the icons */
    gap: 15px;
    margin-top: 1rem;
}

.copyright {
    text-align: center;
    padding-top: 2rem;
}

.social-icons .icon {
    width: 40px;
    height: 40px;
    background-color: #9b59b6; /* Default purple background */
    border-radius: 50%;
    display: inline-block;
    background-size: cover;
    transition: background-color 0.3s;
}

#hr {
    color: aliceblue;
}

.main-area {
    display: flex;
    justify-content:space-evenly;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 130%;
    text-align: left;
    margin-bottom: 2rem;
    padding: 2rem;
}

.social-icons .icon:hover {
    background-color: #8e44ad; /* Darker purple on hover */
}

.twitter-icon {
    background-image: url('./x.jpg');
}

/* Responsive adjustments for small devices */
@media (max-width: 500px) {

    .container-footer{
        padding-left: 8px;
        
    }
    
    .footer-glossy {
        padding: 1rem; /* Reduce padding */
        margin: 0rem; /* Reduce margin */
        width: 145vw; /* Full viewport width */
        /* margin-left: calc(-50vw + 50%); */
        margin-left: 0%;
    }

    .footer-glossy h3 {
        font-size: 1.5rem; /* Adjust font size */
    }
    .footer-glossy ul{
        text-align: center;
    }
    .footer-glossy li{
        text-align: center;
    }

    .email-input {
        width: 100%; /* Full width input */
        margin-bottom: 1rem; /* Space below the input */
    }

    .subscribe-button {
        width: 100%; /* Full width button */
        margin-left: 0; /* Remove left margin */
    }

    .social-icons {
        flex-direction: row; /* Maintain row layout */
        justify-content: space-around; /* Space around the icons */
    }

    .main-area {
        flex-direction: row; /* Stack items vertically */
        align-items: left; /* Center items */
        padding: 1rem; /* Adjust padding */
        width: 100%;
        max-width: 100%;
        
    }

    .copyright {
        font-size: 0.9rem; /* Smaller copyright text */
    }
}


/*.facebook-icon {
    background-image: url('/path-to-facebook-icon.png');
}



.instagram-icon {
    background-image: url('/path-to-instagram-icon.png');
}

.linkedin-icon {
    background-image: url('/path-to-linkedin-icon.png');
}

.text-center {
    text-align: center;
}*/
