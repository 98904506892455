table {
    width:100%;
    border-collapse:collapse
};


@media (max-width: 600px) {
    h2, h3, h4 {
        font-size: 1.5rem;
    }

    .nav-button, .nav-button-Back {
        width: 48%;
        font-size: 10px;
    }

    #tab-content {
        padding: 5px;
    }

    table {
        font-size: 9px;
        width:100%;
    }
    td {
        padding: 6px;
    }


    ul {
        padding-left: 10px;
    }

    pre {
        white-space: pre-wrap; /* Allows code to wrap */
        word-wrap: break-word; /* Ensures long words wrap */
    }
}

/* Flexbox for Tab Buttons */
menu {
    display: fixed;
    direction: row;
    margin: 0px 0;
}

/* Tab button styles */
.tab-button {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #4a90e2;
    border-radius: 5px;
    margin: 0 5px;
}

.tab-button.selected {
    background-color: #4a90e2;
    color: white;
}

@media(max-width:400px) {
    #tab-content {
        padding: 5px;
    }

    table {
        font-size: 9px;
    }
    table {
        width:100%;
        border-collapse:collapse
    };

    td {
        padding: 6px;
    }
    
    

    ul {
        padding-left: 10px;
    }

    pre {
        white-space: pre-wrap; /* Allows code to wrap */
        word-wrap: break-word; /* Ensures long words wrap */
    }


/* Flexbox for Tab Buttons */
menu {
    display: fixed;
    direction: row;
    margin: 0px 0;

}
}