.ccna-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .ccna-table th, .ccna-table td {
    padding: 10px;
    text-align: left;
  }
  
  .ccna-table th {
    background-color: #5A3E6F; /* Dark purple */
    color: white;
    cursor: pointer;
  }
  
  .ccna-table tr:nth-child(even) {
    background-color: #E6DAF1; /* Light purple */
    color: black;
  }
  
  .ccna-table tr:nth-child(odd) {
    background-color: #5A3E6F; /* Dark purple */
    color: white;
  }
  
  .ccna-table td {
    background-color: inherit;
    color: inherit;
  }
  
  .ccna-table th:hover {
    background-color: #7F528A; /* Slightly lighter dark purple for hover */
  }
  
  input {
    width: 300px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  