.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333,0,0,0.7;
  backdrop-filter: blur(2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.matching-result {
  margin-top: 10px;
}

.matching-item-result {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.matching-options-result {
  display: flex;
  flex-direction: column;
}

.matching-options-result span {
  margin-left: 10px;
}

.matching-options-result .correct {
  color: green;
}

.matching-options-result .incorrect {
  color: red;
}

.matching-options-result .unanswered {
  color: orange;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.hamburger-menu {
  background-color: transparent;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.search-container {
  position: relative;
}

.search-icon {
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.search-input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 0;
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

.search-container:hover .search-input {
  width: 150px;
  opacity: 1;
  visibility: visible;
}

.chapter-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.nav-button {
  background-color: #07f90f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.nav-button-Back {
  background-color: #430202;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.chapter-dropdown {
  margin: 0 10px;
}
.quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }

  .result-container {
    position: relative;
  }
  
  /* CSS for full-screen animation overlay */
.animation-overlay {
  position: fixed; /* Position fixed to cover entire screen */
  top: 0;
  left: 0;
  width: 100%; /* Full viewport width */
  height: 100%; /* Full viewport height */
  z-index: 9999; /* High z-index to overlay all content */
  pointer-events: none; /* Prevent clicking through the overlay */
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: center; /* Center align items horizontally */
}

#fireworks-container {
  position: absolute; /* Allow fireworks to expand fully */
  width: 100%; /* Full width of overlay */
  height: 100%; /* Full height of overlay */
  overflow-y: 10000; /* Higher z-index for fireworks to appear above other animations */
}

  .content {
    position: relative;
    z-index: 1; /* Ensure content is below animations */
  }
  

  .result-stats p{
    text-align: left;
    font-weight: bold;
  }

  .result-container {
    padding: 20px;
    max-width: 900px;
    align-items: center;
    margin: 0 auto;
    background: rgba( 128, 0, 255, 0.15 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 5.5px );
-webkit-backdrop-filter: blur( 5.5px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
    color: black;
    display: flex;
    flex-direction: column;
  }

  .question-points{
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    text-align: right;
    margin-left: 88%;
    padding:0px;

  }
  
  .question-card {
    border: 1px solid var(--border);
    /* border: 1px solid indigo; */
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    background-color: var(--background-color);
    position: relative;
    color: var(--text-color);
    width: 100%;
    max-width: 600px;
    min-height: 200px; /* Ensures space for loader */
    
  }
  .question-card-result {
    border: solid 1px var(--border);
    /* border: 1px solid indigo; */
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    background-color: var(--background-color);
    position: relative;
    color: var(--text-color);
    width: 100%;
    max-width: 600px;
    min-height: 200px; /* Ensures space for loader */
  }
  
  .question-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border:none;
    
  }
  
  .quiz-navigation {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .chapter-name {
    display: inline-block;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .progress-bar-container {
    width: 100%;
    background-color: #eee;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 10px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.5s;
  }
/* */
  .progress-info {
    width: 100%;
    max-width: 800px;
    margin-bottom: 1rem;
    text-align: right;
    margin-top: 0%;
  }
  
  .timer-progress {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .timer-bar {
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
    transition: width 1s linear;

  }
  
  .timer-bar.green {
    background-color: #4caf50;
    background-color: #4caf50;
  }
  
  .timer-bar.yellow {
    background-color: #ffc107;
  }
  
  .timer-bar.red {
    background-color: #ff5252;
  }
  
  .timer {
    font-size: 16px;
    font-weight: bold;

  }

  ol .answer-option  {
   
      list-style-type:lower-alpha;
      padding: 2px;
  
    
  }

  /* Add a pointer cursor on hover */
.answer-option {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 400;
    margin-top: 5px;
  }
  
  .answer-option:hover {
    background-color: #5be0f5;
  }
  
  .answer-option.selected {
    background-color: rgb(201, 6, 201); /* Blue highlight for selected */
  }

  .line{
    height: 2px;
    margin-bottom: 5px;
    background-color:#07f90f;
  }

  .chapter-name::first-letter {
    text-transform: capitalize;
  }
  .chapter-card-title::first-letter {
    text-transform: capitalize;
  }


  
  
  @media screen and (max-width: 768px) {
    .question-card {
      max-width: 100%;
    }
    .quiz-container{
        max-width: 100%;
    }
  }


/*
  .result-container {
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    color: black;
  }
  */
  .result-overview {
    margin-bottom: 2rem;
  }
  
  .question-result {
    margin-bottom: 1rem;
  }
  
  .result-image {
    max-width: 100%;
    height: auto;
  }
  
  .chart-container {
    width: 100%;
    height: 300px; /* Adjust this value to change the size of the pie chart */
    margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
    .chart-container {
      height: 200px; /* Adjust for smaller screens */
    }
  }
  
  .answer-result {
    padding: 0.5rem;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
  
  .answer-result.correct {
    background-color: #d4edda;
  }
  
  .answer-result.user-correct {
    background-color: #c3e6cb;
  }
  
  .answer-result.user-wrong {
    background-color: #f8d7da;
  }
  
  .check-mark, .cross-mark {
    margin-left: 0.5rem;
  }

  .correct {
    background-color: #d4edda;
  }
  
  .user-correct {
    color: green;
    font-weight: bold;
  }
  
  .user-wrong {
    color: red;
    font-weight: bold;
  }
  
  .check-mark {
    color: green;
    margin-left: 5px;
  }
  
  .cross-mark {
    color: red;
    margin-left: 5px;
  }
  

  .header-card {
    background-color: rgb(190, 189, 189);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    width: 100%;
  }

  /* Button styles */
.next-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.back-button {
    background-color: #410404; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.submit {
    background-color: #03013d; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.filter {
    background-color: #a8ba06; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

/* Dropdown list styles */
select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 10px 0;
}
  
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog-box {
    background: white;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: black;
  }

 
  .dialog-button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .dialog-button:hover {
    background-color: #ddd;
  }

 /* background: rgba( 255, 255, 255, 0.15 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 8px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );*/
    



  .submit-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  background: rgba( 0,0,0, 0.15 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 8px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: black;
}

.dialog-content button {
    margin: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dialog-content button:first-child {
    background-color: #4CAF50; /* Green */
    color: white;
}

.dialog-content button:last-child {
    background-color: #f44336; /* Red */
    color: white;
}



/* Timer, Progress Bar, and Navigation Styles */
.sticky-top {
    /* position: sticky; */
    width: 70%;
    top: 0;
    background: rgba( 0,0,0, 0.45 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5.5px );
    -webkit-backdrop-filter: blur( 5.5px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );   
     z-index: 1000;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.sticky-bottom {
    justify-content: space-evenly;
    position: sticky;
    bottom: 0;
    background: rgba( 0,0,0, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5.5px );
    -webkit-backdrop-filter: blur( 5.5px );
    border-radius: 8px;
    border: 1px solid rgba( 255, 255, 255, 0.18 ); 
        z-index: 1000;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    width: 70%;
}

.submit-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  background: rgba( 0,0,0, 0.15 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 8px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


/* QUiz Selections Css */

.quiz-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.mode-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.quiz-concept-card {
  cursor: pointer;
  padding: 20px;
  border: 1px solid var(--border);
  /* border: 1px solid #ccc; */
  border-radius: 12px;
  text-align: center;
  width: 200px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #ffffff;

  background: rgba( 255, 255, 255, 0.0 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 10px;
  border: 1px solid var(--border);
  /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
  color:white;
}

.quiz-concept-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

.card-title {
  font-size: 18px;
  margin: 10px 0 5px;
  color: white;
}
/* body[data-theme='light'] .card-title {
  color: #6a5acd;
} */

.card-description {
  font-size: 14px;
  color: white;
}



.chapter-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  background: rgba( 255, 255, 255, 0.0 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  height: fit-content;  
  padding: 10px
}
/*.chapter-section{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  background: rgba( 255, 255, 255, 0.0 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  height: fit-content; 
  width: auto;
}*/

.chapter-card {
  width: 200px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.chapter-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.chapter-card-title {
  font-size: 1.2em;
  margin: 0;
}


@media (max-width: 768px) {
  .quiz-container,
  .result-container {
    max-width: 100%;
    padding: 10px;
  }

  .quiz-container {
    padding: 10px;
    flex-direction: column;
  }

  .result-container {
    padding: 10px;
    max-width: 100%;
  }

  .animation-overlay,
  #fireworks-container {
    width: 100%;
    height: 100%;
  }

  .question-points {
    margin-left: 0;
    text-align: center;
  }

  .question-card,
  .question-card-result {
    max-width: 100%;
    padding: 15px;
    margin: 5px 0;
  }

  .question-list {
    flex-direction: column;
  }

  .quiz-navigation {
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }

  .chapter-name {
    font-size: 20px;
  }

  .progress-bar-container {
    height: 8px;
  }

  .progress-info {
    max-width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .timer-progress {
    flex-direction: column;
    align-items: center;
  }

  .timer {
    font-size: 14px;
  }

  .next-button,
  .back-button,
  .submit,
  .filter {
    padding: 10px 20px;
    font-size: 10px;
    margin: 2px;
  }

  .quiz-concept-card {
    width: 150px;
    padding: 15px;
  }

  .chapter-card {
    width: 150px;
    padding: 15px;
  }

  .chapter-card-title {
    font-size: 1em;
  }

  .sticky-top,
  .sticky-bottom {
    width: 100%;
    padding: 5px 0;
  }

  .submit-dialog {
    padding: 10px;
  }

  .dialog-content {
    padding: 10px;
  }

  .chapter-name::first-letter {
    text-transform: capitalize;
  }
  .chapter-card-title::first-letter {
    text-transform: capitalize;
  }

  
}



@media (max-width: 480px) {
  .quiz-container,
  .result-container {
    max-width: 100%;
    padding: 8px;
  }

  .quiz-container {
    padding: 8px;
    flex-direction: column;
  }

  .result-container {
    padding: 8px;
    max-width: 100%;
  }

  .animation-overlay,
  #fireworks-container {
    width: 100%;
    height: 100%;
  }

  .question-points {
    margin-left: 0;
    text-align: center;
  }

  .question-card,
  .question-card-result {
    max-width: 100%;
    padding: 12px;
    margin: 4px 0;
  }

  .question-list {
    flex-direction: column;
  }

  .quiz-navigation {
    flex-direction: column;
    gap: 4px;
    margin-top: 8px;
  }

  .chapter-name {
    font-size: 18px;
  }

  .progress-bar-container {
    height: 6px;
  }

  .progress-info {
    max-width: 100%;
    text-align: center;
    margin-top: 8px;
  }

  .timer-progress {
    flex-direction: column;
    align-items: center;
  }

  .timer {
    font-size: 12px;
  }

  .next-button,
  .back-button,
  .submit,
  .filter {
    padding: 8px 16px;
    font-size: 9px;
    margin: 1px;
  }

  .quiz-concept-card,
  .chapter-card {
    width: 120px;
    padding: 12px;
  }

  .chapter-card-title {
    font-size: 0.9em;
  }

  .sticky-top,
  .sticky-bottom {
    width: 100%;
    padding: 4px 0;
  }

  .submit-dialog,
  .dialog-content {
    padding: 8px;
  }

  .chapter-name::first-letter {
    text-transform: capitalize;
  }
  .chapter-card-title::first-letter {
    text-transform: capitalize;
  }

}

