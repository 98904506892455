.chapter-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    
  }
  
  .nav-button-Back,
  .nav-button {
    background-color: #4caf50; /* Green */
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .nav-button-Back:disabled,
  .nav-button:disabled {
    background-color: #ccc; /* Gray */
    cursor: not-allowed;
  }
  
  .chapter-dropdown {
    margin: 0 15px;
  }
  
  .chapter-dropdown select {
    padding: 10px;
    font-size: 16px;
  }
@media(orientation: portrait) and (max-width:540px) {

    .chapter-navigation {
        display: flex;
        align-items: center;
        justify-content:space-around;
        padding-left: 100px;
        margin: 20px 0;
        width: 110%;
        max-width: 110%;
      }

      .chapter-dropdown {
        margin: 0 15px;
        width: 300px;
      }
}  