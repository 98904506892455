body{
    line-height: 1.5;
    font-family: sans-serif;
}
*{
   margin: 0;
   padding: 0;
   box-sizing: border-box; 
}
.container{
    max-width: 1170px;
    background-color: blue;
    margin: auto;
}
.row{
    display: flex;
    flex-wrap: wrap ;
}
ul{
    list-style: none;
}
.footer{
/* background-color: #24262b; */
background-color: var(--background-color);
padding: 70px 0;
border: 1px solid indigo;
border-radius: 8px;
position: relative;

}
.footer-col{
    width: 25%;
    padding: 0 15px;
}
.footer-col h4{
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500 ;
    position:relative;
}
.footer-col h4::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #e91e63;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}
.footer-col ul li:not(:last-child){
    margin-bottom: 10px;

}
.footer-col ul li a{
    font-size:16px;
    text-transform: capitalize;
    color:#ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
}

.footer-col ul li a:hover{
    color:#ffffff;
    margin-left: 8px;
}
.footer-col .social-links a{
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255,255,255,0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius:50%;
    color: #ffffff;
    transition: all 0.4s ease;
}
.footer-col .social-links a:hover{
  color:#24262b;
  background-color: #ffffff;  
}

@media(max-width: 767px){
    .footer-col{
        width: 50%;
        margin-bottom: 30px;
    }
}
@media(max-width: 574px){
    .footer-col{
        width: 100%;
    }
}
@media (max-width: 574px) {
    .footer {
        padding: 30px 0; /* Further adjust padding for very small screens */
    }
}
