/* src/components/ImageEnhancer.css */
/* src/components/ImageEnhancer.css */
.image-container {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    width: 100%; /* Ensure it takes full width of the parent */
    height: auto; /* Maintain aspect ratio */
}

.watermark {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to truly center */
    color: purple;
    opacity: 0.7; /* Always visible */
    font-size: 30px; /* Adjust font size for visibility */
    pointer-events: none; /* Prevent watermark from interfering with clicks */
    text-align: center; /* Center text */
}

.thumbnail {
    width: 100%;
    height: auto;
}

/* src/components/ImageEnhancer.css */
.watermark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    pointer-events: none; /* Allow clicks to pass through */
}

/* src/components/ImageEnhancer.css */

.watermark.visible {
    opacity: 0.7; /* Visible when triggered */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s;
}

.modal-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    animation: popIn 0.5s;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes popIn {
    from { transform: scale(0.5); }
    to { transform: scale(1); }
}