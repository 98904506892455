/* CSS to hide the bottom border of specific table rows */
.no-bottom-border td {
    border-bottom: none !important;
}
.num-list{
    list-style-type:decimal;
    padding-left: 50px;
}

.skills-list {
    list-style-type:circle; /* or circle, square, etc. */
    padding-left: 50px; /* Adjust as needed */
    color: white; /* Customize text color */
    font-size: 16px; /* Customize font size */
    padding-top: 0px;
}
.skills-list li {
    margin-bottom: 2px; /* Add space between items */
}
.skills-list-note {
    list-style-type:circle; /* or circle, square, etc. */
    padding-left: 50px; /* Adjust as needed */
    color: red; /* Customize text color */
    font-size: 16px; /* Customize font size */
    padding-top: 0px;
}
.line-spacing{
    line-height:2;
}

.intro{
    font-weight: bolder;
    font-size: 16px;
    font-style: oblique;
}

.insturtion{
    text-align: left;
}

.ITN-skills-list{
    list-style-type: lower-alpha;
    padding-left: 32px;
}
.ITN-skills-list li{
    padding-left: 32px;
}


/* Initialize the custom counter and set its starting value */
ol.custom-list {
  counter-reset: custom-counter 1; /* Starts from the letter */
  padding-left: 32px;
}


ol.custom-list li {
  list-style: none; /* Remove default list style */
  counter-increment: custom-counter; /* Increment counter */
  position: relative; /* Position to use pseudo-element */
  
}

/* Define the pseudo-element to display custom letters */
ol.custom-list li::before {
  content: counter(custom-counter, lower-alpha) ". "; /* Start from 'D' and use uppercase letters */
  position: absolute; /* Position pseudo-element */
  left: -1.5em; /* Adjust as needed */
}

/* For Download button */

.colored-image {
    filter: hue-rotate(540deg) saturate(3.9) brightness(0.7); /* Adjust color filter */
  }

  ol.custom-list-letters {
    counter-reset: custom-counter; /* Reset the counter */
    padding-left: 32px; /* Indent the list */
}

ol.custom-list-letters li {
    counter-increment: custom-counter 5; /* Increment the counter */
    list-style-type: none; /* Remove default numbering */
    padding: 0%;
}

ol.custom-list-letters li::before {
    content: counter(custom-counter, lower-alpha) ". "; /* Use lower-alpha for letters */
    /* font-weight: bold;  */
}

  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .bounce-on-click:active {
    animation: bounce 1s;
  }

  @media(max-width:600px) {
    .no-bottom-border td {
      border-bottom: none !important;
  }
  .num-list{
      list-style-type:decimal;
      padding-left: 50px;
  }
  
  .skills-list {
      list-style-type:circle; /* or circle, square, etc. */
      padding-left: 50px; /* Adjust as needed */
      color: white; /* Customize text color */
      font-size: 16px; /* Customize font size */
      padding-top: 0px;
  }
  .skills-list li {
      margin-bottom: 0px; /* Add space between items */
  }
  .skills-list-note {
      list-style-type:circle; /* or circle, square, etc. */
      padding-left: 50px; /* Adjust as needed */
      color: red; /* Customize text color */
      font-size: 16px; /* Customize font size */
      padding-top: 0px;
  }
  .line-spacing{
      line-height:2;
  }
  
  .intro{
      font-weight: bolder;
      font-size: 16px;
      font-style: oblique;
  }
  
  .insturtion{
      text-align: left;
  }
  
  .ITN-skills-list{
      list-style-type: lower-alpha;
      padding-left: 32px;
  }
  ol.ITN-skills-list li{
    position: relative;
      /* padding-left: 0px; */
  }
  
  
  /* Initialize the custom counter and set its starting value */
  ol.custom-list {
    counter-reset: custom-counter 1; /* Starts from the letter */
    padding-left: 32px;
  }
  
  ol.custom-list li {
    list-style: none; /* Remove default list style */
    counter-increment: custom-counter; /* Increment counter */
    position: relative; /* Position to use pseudo-element */
  }
  
  /* Define the pseudo-element to display custom letters */
  ol.custom-list li::before {
    content: counter(custom-counter, lower-alpha) ". "; /* Start from 'D' and use uppercase letters */
    position: absolute; /* Position pseudo-element */
    left: -1.5em; /* Adjust as needed */
  }
  
  /* For Download button */
  
  .colored-image {
      filter: hue-rotate(540deg) saturate(3.9) brightness(0.7); /* Adjust color filter */
    }
    
    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-50px);
      }
      100% {
        transform: translateY(0);
      }
    }
    
    .bounce-on-click:active {
      animation: bounce 1s;
    }
  }

  .ITN-table {
    width: 100%; /* Make the div take full width */
    height: 100%; /* Make the div take full height */
    overflow: hidden; /* Prevent overflow if the table grows too large */
    display: flex; /* Use flex to help with alignment */
  }
  
  .ITN-table table {
    width: 100%; /* Table takes full width of the div */
    height: 100%; /* Table takes full height of the div */
    border-collapse: collapse; /* Optional: Collapse borders */
  }
  
  .ITN-table th, .ITN-table td {
    width:50%; /* Adjusts to fit content */
    height: 50%; /* Adjusts to fit content */
    padding: 8px; /* Optional: Add some padding */
    text-align: left; /* Optional: Align text */
    border: 1px solid #ccc; /* Optional: Add border */
    text-wrap: wrap;
  }
  
  .no-bottom-cell-border {
    border-bottom: none; /* Hide the bottom border for the last row */
  }

  .addressing-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
.addressing-table th,
.addressing-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
.addressing-table th {
    /* background-color: #f2f2f2; */
    font-weight: bold;
}
  


