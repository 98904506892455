/*
* {
  box-sizing: border-box;
}

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-gray-100: #f4f3f1;
  --color-gray-200: #dddbd8;
  --color-gray-300: #ccc9c6;
  --color-gray-400: #aeaba7;
  --color-gray-500: #8a8784;
  --color-gray-600: #656360;
  --color-gray-700: #4b4a47;
  --color-gray-800: #31302e;
  --color-gray-900: #1f1d1b;

  --color-primary-100: #fcf3e1;
  --color-primary-200: #fceccd;
  --color-primary-300: #fae1af;
  --color-primary-400: #fbd997;
  --color-primary-500: #ffd37c;
  --color-primary-600: #f9c762;
  --color-primary-700: #fbc14d;
  --color-primary-800: #fab833;
  --color-primary-900: #f6ad1b;
}

body {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

*/

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

:root{
  background: var(--background-color);
}

/* index.css */
/* update css */
.update-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #28a745;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  z-index: 1000;
}



html {
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
  color: #ebe7ef;
  background: radial-gradient(#280a48, #20043d);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
  background-color: var(--background-color);
}
/* html[data-theme='light']  {
  background-color: var(--background-color);;
} */
 




main {
  width: 100%;
  max-width: 50rem;
  margin: auto;
}


main h2 {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 0 1.5rem 0;
  color: #a18aba;
}

#core-concepts ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  
}

#core-concepts li {
  width: 10rem;
  text-align: center;
}

#core-concepts li img {
  height: 4rem;
  width: 6rem;
  object-fit: cover;
}

#core-concepts li h3 {
  margin: 0.5rem 0;
}

#core-concepts li p {
  font-size: 0.9rem;
}

#tab-sections {
  margin: 3rem auto;
  word-wrap: break-word;
}

#tab-sections h2 {
  text-align: left;
}

#tab-sections menu {
  margin: 1rem 0;
  padding: 0;
  display: flex;
  gap: 0.5rem;
  list-style: none;
}

#tab-sections menu button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  color: #a18aba;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#tab-sections menu button:hover {
  background-color: #1b082f;
  color: #ebe7ef;
}

#tab-sections menu button.active {
  background-color: #7925d3;
  color: #ebe7ef;
}

#tab-content {
  padding: 1rem;
  border-radius: 6px;
  background-color: #2f1d43;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  text-wrap: wrap;
  width: auto;
  color:white;
}
#tab-content h2{
  text-align: left;
  margin: 0;
}

#tab-content h3 {
  margin: 0;
}

#tab-content code {
  font-size: 1rem;
}

/*#tab-content .topic-tab-image{
  height: 4rem;
  width: 6rem;
  object-fit: cover;
}*/

#tab-content .topic-tab-image {
  height: 4rem;
  width: 6rem;
  object-fit: cover;
  margin: 0 auto; /* Centers the image horizontally */
  animation: fadeIn 3s ease-in-out; /* Adds a fade-in animation */
}

/* Define the fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* General button styling */

#tab-learn button {
  background-color: black; /* Black background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* Remove default border */
  border-radius: 25px; /* Round edges */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  position: relative; /* Positioning for the gradient overlay */
  overflow: hidden; /* Hide overflow for the gradient */
  z-index: 1; /* Ensure button text is on top */
  transition: all 0.3s ease; /* Smooth transition */
}

/* Gradient overlay */
#tab-learn button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%; /* Make the gradient larger to cover the animation */
  height: 200%; /* Make the gradient larger to cover the animation */
  background: linear-gradient(45deg, purple, pink, purple, pink);
  background-size: 400% 400%; /* Size for smooth animation */
  animation: gradientLoop 5s ease infinite; /* Looping animation */
  z-index: -1; /* Place gradient behind the button text */
  filter: blur(8px); /* Slight blur for effect */
}

/* Keyframes for the gradient animation */
@keyframes gradientLoop {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Hover effect */
#tab-learn button:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}


/* Custom styling for the specific button */
.custom-route-button {
  background-color: black; /* Black background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* Remove default border */
  border-radius: 25px; /* Round edges */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  position: relative; /* Positioning for the gradient overlay */
  overflow: hidden; /* Hide overflow for the gradient */
  z-index: 1; /* Ensure button text is on top */
  transition: all 0.3s ease; /* Smooth transition */
}

/* Gradient overlay */
.custom-route-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%; /* Make the gradient larger to cover the animation */
  height: 200%; /* Make the gradient larger to cover the animation */
  background: linear-gradient(45deg, purple, #ea00ff, purple, #ea00ff);
  background-size: 400% 400%; /* Size for smooth animation */
  animation: gradientLoop 5s ease infinite; /* Looping animation */
  z-index: -1; /* Place gradient behind the button text */
  filter: blur(8px); /* Slight blur for effect */
}

/* Keyframes for the gradient animation */
@keyframes gradientLoop {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Hover effect */
.custom-route-button:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}
li{
  line-height: 2;
  text-Align:left;
  text-decoration: wavy;
}

/* Theme styles */
body[data-theme='light'] {
  background: radial-gradient(#6c15ca, #46127e);
  /* --background-color: #0494a7; */
  --text-color: #fefeff;
  --border: rgb(239, 235, 241);
}
/* [data-theme='light'] :root {
  background-color: #fff;
}
:root{
  --background: rgba(255,255,255,255)
} */

body[data-theme='dark'] {
  --background-color: #000000;
  /* --text-color: #ffffff; */
  --text-color: #fff;
  --border: Indigo;
}


/* Change: Added default theme styles */
/* Default Theme Styles */
body[data-theme='default'] {
  --border: rgb(239, 235, 241);
  --text-color: #fefeff;
  color: #ebe7ef;
  background: radial-gradient(#280a48, #20043d);
  font-family: "Quicksand", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  min-height: 80rem;
}

/* General Box Sizing */
* {
  box-sizing: border-box;
}

/* HTML and Body Styling for Default Theme */
html {
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
  color: #ebe7ef;
  background: radial-gradient(#280a48, #20043d);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
}

/* Styles for Main Content */
main {
  width: 90%;
  max-width: 50rem;
  margin: auto;
}

main h2 {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 0 1.5rem 0;
  color: #a18aba;
}

/* Core Concepts Section Styling */
#core-concepts ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

#core-concepts li {
  width: 10rem;
  text-align: center;
}

#core-concepts li img {
  height: 4rem;
  width: 6rem;
  object-fit: cover;
}

#core-concepts li h3 {
  margin: 0.5rem 0;
}

#core-concepts li p {
  font-size: 0.9rem;
}

/* Tab Sections Styling */
#tab-sections {
  margin: 3rem auto;
}

#tab-sections h2 {
  text-align: left;
}

#tab-sections menu {
  margin: 1rem 0;
  padding: 0;
  display: flex;
  gap: 0.5rem;
  list-style: none;
}

#tab-sections menu button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  color: #a18aba;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#tab-sections menu button:hover {
  background-color: #1b082f;
  color: #ebe7ef;
}

#tab-sections menu button.active {
  background-color: #7925d3;
  color: #ebe7ef;
}

/* Tab Content Styling */
#tab-content {
  padding: 1rem;
  border-radius: 6px;
  background-color: #2f1d43;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
  width: auto;

}

#tab-content h2 {
  text-align: left;
  margin: 0;
}

#tab-content h3 {
  margin: 0;
}

#tab-content code {
  font-size: 1rem;
}

#tab-content .topic-tab-image {
  height: 4rem;
  width: 3rem;
  object-fit: cover;
  margin: 0 auto;
  
  animation: fadeIn 3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Styling for the Tab Learn Button */
#tab-learn button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease;
}

#tab-learn button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, purple, pink, purple, pink);
  background-size: 400% 400%;
  animation: gradientLoop 5s ease infinite;
  z-index: -1;
  filter: blur(8px);
}

@keyframes gradientLoop {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#tab-learn button:hover {
  transform: scale(1.05);
}

/* Styling for Custom Route Button */
.custom-route-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease;
}

.custom-route-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, purple, #ea00ff, purple, #ea00ff);
  background-size: 400% 400%;
  animation: gradientLoop 5s ease infinite;
  z-index: -1;
  filter: blur(8px);
}

.custom-route-button:hover {
  transform: scale(1.05);
}

/* List Styling */
li {
  line-height: 2;
  text-align: left;
  text-decoration: wavy;
}

/* Media Queries for Mobile */
@media (max-width: 480px) {
  * {
    box-sizing: border-box;
  }
  

  html {
    font-family: "Quicksand", sans-serif;
    line-height: 1.5;
    color: #ebe7ef;
    background: radial-gradient(#280a48, #20043d);
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    min-height: auto;
  }

  main {
    width: auto;
    max-width: auto;
    margin: auto;
  }

  main h2 {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    margin: 0 0 1rem 0;
    color: #a18aba;
    font-size: 1.5rem;
  }

  #core-concepts ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    margin: 0;
  }

  #core-concepts li {
    width: 100%;
    text-align: center;
  }

  #core-concepts li img {
    height: 3rem;
    width: 4rem;
  }

  #core-concepts li h3 {
    margin: 0.5rem 0;
    font-size: 1rem;
  }

  #core-concepts li p {
    font-size: 0.8rem;
  }

  #tab-sections {
    margin: 0rem auto;
    overflow: visible;
  }

  #tab-sections h2 {
    text-align: left;
    font-size: 1.2rem;
  }

  #tab-sections menu {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }

  #tab-sections menu button {
    padding: 0.5rem;
    font-size: 0.9rem;
    border-radius: 4px;
  }

  #tab-sections menu button:hover {
    background-color: #1b082f;
    color: #ebe7ef;
  }

  #tab-sections menu button.active {
    background-color: #7925d3;
    color: #ebe7ef;
  }

  #tab-content {
    padding: 0.5rem;
    border-radius: 4px;
    background-color: #2f1d43;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    width: auto; 
    max-width: auto;
    margin: 0 auto;  
    overflow-wrap: break-word;
    color:white;
    
  }

  #tab-content h2 {
    text-align: left;
    font-size: 1.2rem;
    margin: 0;
  }

  #tab-content h3 {
    font-size: 1rem;
  }

  #tab-content code {
    font-size: 0.9rem;
  }

  #tab-content .topic-tab-image {
    height: 3rem;
    width: 4rem;
  }
}


body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Styles for input fields and question cards */
input, .question-card {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--text-color);
}

@media (max-width: 400px) {
  * {
    box-sizing: border-box;
  }

  html {
    font-family: "Quicksand", sans-serif;
    line-height: 1.5;
    color: #ebe7ef;
    background: radial-gradient(#280a48, #20043d);
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    min-height: auto;
  }

  main {
    width: 155%;
    max-width: 155%;
    margin: auto;
  }

  main h2 {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    margin: 0 0 1rem 0;
    color: #a18aba;
    font-size: 1.5rem;
  }

  #core-concepts ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    margin: 0;
  }

  #core-concepts li {
    width: 100%;
    text-align: center;
  }

  #core-concepts li img {
    height: 3rem;
    width: 4rem;
  }

  #core-concepts li h3 {
    margin: 0.5rem 0;
    font-size: 1rem;
  }

  #core-concepts li p {
    font-size: 0.8rem;
  }

  #tab-sections {
    margin: 0rem auto;
    overflow: visible;
  }

  #tab-sections h2 {
    text-align: left;
    font-size: 1.2rem;
  }

  #tab-sections menu {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }

  #tab-sections menu button {
    padding: 0.5rem;
    font-size: 0.9rem;
    border-radius: 4px;
  }

  #tab-sections menu button:hover {
    background-color: #1b082f;
    color: #ebe7ef;
  }

  #tab-sections menu button.active {
    background-color: #7925d3;
    color: #ebe7ef;
    width: 100%;
  }

  #tab-content {
    padding: 0.5rem;
    border-radius: 4px;
    background-color: #2f1d43;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    width: auto; /* Set the desired width */
    max-width: auto;
    margin: 0 auto; /* Center the content */
  
    
  }

  #tab-content h2 {
    text-align: left;
    font-size: 1.2rem;
    margin: 0;
  }

  #tab-content h3 {
    font-size: 1rem;
  }

  #tab-content code {
    font-size: 0.9rem;
  }

  #tab-content .topic-tab-image {
    height: 3rem;
    width: 4rem;
  }
}

/* @media (max-width: 600px){
  #tab-content{
    width: auto;
    text-wrap: wrap;
  }

} */


body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Styles for input fields and question cards */
input, .question-card {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--text-color);
}

.popup-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
background: rgba( 0,0,0, 0.15 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 5.5px );
-webkit-backdrop-filter: blur( 5.5px );
border-radius: 8px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-content button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #0056b3;
}




