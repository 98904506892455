.course-card {
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    width: 300px;
    background: rgba( 255,255,255 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
  }
  
  .course-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .course-card-image {
    width: 100%;
    border-radius: 8px;
    
  }
  
  .course-card-content {
    text-align: center;
    padding: 8px 0;
    justify-items: center;
    
  }
  
  .course-card-actions button {
    margin: 4px;
    padding: 4px 8px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .course-card-actions button:hover {
    color: #007bff;
  }
  
  .popular-courses {
    padding: 20px;
    margin: 20px 0;
  }
  
  .courses-container {
    display: flex;
    gap: 20px;
  }

  .tracking-component{
    border-radius: 10px;
    border: white;
  }
  