.robot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 2rem; /* Adjust the size of the robot emoji */
    transition: all 0.5s ease;
    z-index: 1000;
    cursor: pointer;
    animation: float 2s infinite;

  }
  
  .robot.top-card {
    bottom: 50%;
    right: 22%;
    transform: translateX(50%) rotate(360deg);
  }
  
  .robot-speech {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
    text-align: center;
  }
  
  .hidden {
    display: none;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
}
  