html, body {
  position: relative; /* Ensure the stacking context is set for the whole document */
}

.update-notification {
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 5px;
  z-index: 9999; /* High z-index to ensure it's on top */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
}

.notification-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

  
  .notification-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .notification-actions button:first-child {
    background-color: #ffffff;
    color: #4CAF50;
  }
  
  .notification-actions button:last-child {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
  }