header {
    text-align: center;
    margin: 3rem 0;
  }
  
  header img {
    height: 7rem;
    width: 10rem;
    object-fit: cover;
  }
  
  header h1 {
    margin: 0;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 5rem;
    background: linear-gradient(40deg, #ea00ff, #ea00ff, #03d5ff, #03d5ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.5));
    font-display: swap;
  }
  
  header p {
    margin: 0;
    font-size: 1.25rem;
    color: #8964b0;
    font-family: "Roboto Condensed", sans-serif;
    font-display: swap;
  }

  @media (max-width: 600px) and (orientation: portrait){    
    header img {
      height: 5 rem;
      width: 8 rem;
      object-fit: cover;
    }
    
    header h1 {
      margin: 0;
      font-family: sans-serif;
      font-size: 3rem;
    }
    
    header p {
      font-size: 1 rem;
      font-family: sans-serif;
    }
  
  }